<template>
    <div class="fillcontain">
      <div class="table_container">
        <el-card class="box-card">
        	<div  style="text-align:center;height:25px;vertical-align: middle;line-height: 25px" class="clearfix">
			   	<el-button type="text" icon="el-icon-back"
			   	class="leftst" 
			   	style="" 
			   	@click='handlerLeftBtn'>
			   		返回
			   	</el-button>
			    <span class="titles" >订单列表</span>
			    <!-- <el-button type="text" style="padding:0;float:right;vertical-align: middle;margin-right:5px;"   @click='handlerRightBtn'>添加</el-button> -->
			  </div>

          <div class="fixbox">
            <div class="filter-content" slot="content">
              <el-form  
                @submit.native.prevent 
                label-width="120px"
                size="mini"
                >
                <el-form-item label="关键字搜索">
                  <el-input 
                    placeholder="请输入内容" 
                    @keyup.enter.native="getTableData(true)" 
                    v-model="fetchOptions.keywords">
                  </el-input>
                </el-form-item>                  
              </el-form>
              <el-row :gutter="24" style="text-align:right;marginRight:150px;">
        <el-button type="primary" style="width:100px;padding:10px 20px; cursor:pointer;marginRight:100xp;" @click="onFilter">
          <i  class="fa fa-filter"></i>
          筛选
        </el-button>
        <el-button type="primary" style="width:100px;padding:10px 20px; cursor:pointer" @click="onReset">
          重置
        </el-button>
      </el-row>
            </div>

          </div>
          <el-table
            v-loading="isFetching"
            :data="tableData"
            border
            size="small"
            @sort-change="handleSortChange"
            highlight-current-row
            style="width:100%;padding:0">
            <el-table-column
              prop="ord_number"
              label="订单号"
              width="120"
              align='center'>
            </el-table-column>
            <el-table-column
              prop="ord_time"
              label="下单时间"
              align='center'>
            </el-table-column>
            <el-table-column
              prop="ord_time"
              label="下单时间"
              align='center'>
            </el-table-column>
            <el-table-column
              prop="ord_payment_method"
              label="支付方式"
              align='center'>
            </el-table-column>
            <el-table-column
              prop="ord_status"
              label="订单状态"
              align='center'>
                <template slot-scope="scope">
                <el-tag v-if="scope.row.ord_status==1" type="success">
                  使用中
                </el-tag>
                <el-tag v-else type="info">
                  停用
                </el-tag>
                </template>
            </el-table-column>
            <el-table-column 
              label="操作" 
              width="100" 
              align='center'>
              <template slot-scope="scope">
                <div>
                  <el-dropdown trigger="click">
                    <el-button type="primary" size="mini">
                      <i class="fa fa-gear"></i>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                     
                      <el-dropdown-item 
                        @click.native="onDetail(scope.row)">
                        详情
                      </el-dropdown-item>
                      <el-dropdown-item 
                        @click.native="onDelete(scope.row,scope.$index)">
                        删除
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>                
                </div>
              </template>
            </el-table-column>
          </el-table>
           <el-row>
            <el-col :span="24">
            	<div class="pagination">
                 <el-pagination
				      @size-change="handleSizeChange"
				      @current-change="handleCurrentChange"
				      :current-page="currentPage4"
				      :page-sizes="[10, 30, 50, 100]"
				      :page-size="100"
				      layout="total, sizes, prev, pager, next, jumper"
				      :total="400">
				    </el-pagination>
				</div>
            </el-col>
        </el-row>

        </el-card>
      </div>
     <!--  <el-dialog 
            title="管理员添加" 
          :visible.sync="addvisible"
          :modal-append-to-body="false">
          <div class="form">
              <el-form 
                  ref="form" 
                  :model="addForm"
                  :rules="form_rules"
                  label-width="120px" 
                  style="margin:10px;width:auto;">
                  <el-form-item prop='username'  label="用户名:">
                      <el-input  v-model="addForm.username"></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="addForm.password" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码" prop="password_confirmation">
                    <el-input type="password" v-model="addForm.password_confirmation" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="是否启用" prop="is_use">
                    <el-switch
                      v-model="addForm.is_use"
                      active-value="1"
                      inactive-value="2">
                    </el-switch>
                  </el-form-item>
                  <el-form-item label="用户角色">
                    <el-checkbox-group v-model="addForm.role_id">
                      <el-checkbox v-for="item in roledata" :label="item.id" :key="item.id">{{item.role_name}}</el-checkbox> 
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item  class="text_right">
                      <el-button type="primary" @click='onSubmit("form")'>提 交</el-button>
                  </el-form-item>
              </el-form>
          </div>
      </el-dialog> -->
      <el-dialog 
           title="订单详情" 
          :visible.sync="editvisible"
          :modal-append-to-body="false">
          <div class="form">
              <el-form 
                ref="form" 
                :model="detailForm"
                label-width="120px" 
                style="margin:10px;width:auto;">
                <el-form-item prop='username'  label="订单号:">
                	{{detailForm.username}}
                   <!--  <el-input  v-model="detailForm.username"></el-input> -->
                	
                </el-form-item>
                <el-form-item label="订单时间" prop="password">
                  <el-input type="password" v-model="detailForm.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="订单状态" prop="password_confirmation">
                  <el-input type="password" v-model="detailForm.password_confirmation" autocomplete="off"></el-input>
                </el-form-item>
               
                <el-form-item  class="text_right">
                  <el-button type="primary" @click='editSubmit("form")'>提 交</el-button>
                </el-form-item>
              </el-form>
          </div>
      </el-dialog>
    </div>
</template>

<script>
  
    export default {
      data(){
      
        return {
         currentPage4:1,
          isFetching:false,
          tableData: [
          {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
          {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
          {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
          {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          },
           {'ord_id' :'353145348975',
		  'ord_number' :'353145348975',
		  'ord_product_total':'200',
		  'ord_status':'支付中',
		  'ord_time':'2020-12-20',
		  'ord_user_payment_time': '2020-12-02',
		  'ord_payment_method':'微信',
          }
          ],
          total:null, //总页数
          fetchOptions:{
            limit:20,
            page:1,
            keywords:"",
            order:{login_time:'desc'},
          },
          editvisible:false,
          detailForm:{
            id:'',
            username:"", 
            password:"",
            password_confirmation:"",
            role_id:[],
          },
          // roledata:[],//角色列表

      
        }
      },
      components: {
        
      },
      created(){
          
      },
      mounted(){
          // this.getTableData()
          // this.$store.dispatch("GET_ROLE_LIST_SIMPLE").then((res)=>{
          //   console.log("roledata",res)
          //   this.roledata=res.list
          // })
      },
      methods: {
        getTableData(isFeater){
          this.isFetching=true;
          if(isFeater){
            this.fetchOptions.page=1;
          }
          this.$store.dispatch("GET_ADMIN_LIST",this.fetchOptions).then((res)=>{
            console.log("tableData",res)
            this.tableData=res.list;
            this.total=res.list.count;
            this.isFetching=false;
          })
        },
        handlerLeftBtn(){
           this.$router.back();
        },
        onFilter(){

        },
        onReset(){

        },
        onDetail(val){
        	console.log(val,11)
          // this.detailForm.id=val.ord_id;
          this.$router.push({
            path:'/user/orderDetails',
            query:{
              id:val.ord_id
            }
          })
          // this.detailForm.username=val.username;
          // this.detailForm.role_id=val.role;
          // this.editvisible = true;
        },
        editSubmit(){
          this.$store.dispatch("ADMIN_EDIT",this.detailForm).then((res)=>{
            this.editvisible=false,
            this.getTableData()
            this.detailForm.password="";
            this.detailForm.password_confirmation=""
          })
        },
        onDelete(val,index){
            this.$confirm('是否确认删除, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              this.$store.dispatch("ADMIN_DEL",{id:val.id}).then((res)=>{
                console.log(res)
                if(res.error==0){
                  this.getTableData(true)
                  this.$message({
                    type: 'success',
                    message:res.content
                  });
                } 
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
        },
  

        //启用禁用状态
        changeStatus(val){
          console.log(val)
          this.$store.dispatch('PRIVILEGE_SWITCH',{id:val.id,is_use:val.is_use}).then((res)=>{
            this.getTableData(true)
          })
        },
         handleSizeChange(val) {
	        console.log(`每页 ${val} 条`);
	      },
	      handleCurrentChange(val) {
	        console.log(`当前页: ${val}`);
	      },
        handleSortChange(val){
          var order="";
          if(val.order=='descending'){
            order='desc'
          }else{
            order="asc";
          }
          this.fetchOptions.order=`{${val.prop}:${order}}`;
          this.getTableData();
        },
      }
    };
</script>

<style lang="less" scoped>
.leftst{
	float:left;
	padding:0;
	vertical-align: middle;
	margin-left:5px;
	font-size:16px;
	color:#666;
}
.titles{
	font-size:16px;
}
.clearfix{
	margin-bottom:20px;
}
.pagination{
        text-align: center;
        margin-top: 10px;
}
.fixbox{
	margin-bottom:20px;
}
.box-card{
  margin:15px;
}

</style>


